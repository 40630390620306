import React from 'react';
import '../styles/Footer.css';
import { FaXTwitter, FaInstagram, FaYoutube, FaLinkedin } from 'react-icons/fa6';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-icons">
        <a href="https://x.com/numeroalphaa" target="_blank" rel="noopener noreferrer"><FaXTwitter /></a>
        <a href="https://www.instagram.com/numeroalphaa" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
        <a href="https://www.youtube.com/@NumeroAlpha" target="_blank" rel="noopener noreferrer"><FaYoutube /></a>
        <a href="https://www.linkedin.com/company/numeroalphaa/" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
      </div>
      <div className="footer-company">
        &copy; {new Date().getFullYear()} NumeroAlpha LLP
      </div>
    </footer>
  );
};

export default Footer;
