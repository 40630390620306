import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import '../styles/ContactPage.css';

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.name || !formData.email) {
      alert('Please fill out both the Name and Email fields.');
      return;
    }

    // EmailJS send function
    emailjs.send(
      'service_d4d75ra', //  EmailJS service ID
      'template_qsg3tz5', //  EmailJS template ID
      {
        from_name: formData.name,
        from_email: formData.email,
        message: formData.message
      },
      'B3-lfoV6RI3OFp5gM' //  EmailJS User ID
    )
    .then((result) => {
      console.log(result.text);
      alert('Message successfully sent!');
    }, (error) => {
      console.log(error.text);
      alert('Failed to send the message, please try again.');
    });

    setFormData({ name: '', email: '', message: '' }); 
  };

  return (
    <div className="contact-page">
      <div className="contact-form-container">
        <h1>Get in touch</h1>
        <p>Your feedback is valuable!</p>
        <div className="contact-form">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input 
                type="text" 
                id="name"
                name="name" 
                value={formData.name} 
                onChange={handleChange} 
                required 
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input 
                type="email" 
                id="email"
                name="email" 
                value={formData.email} 
                onChange={handleChange} 
                required 
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea 
                id="message"
                name="message" 
                placeholder="(optional)" 
                value={formData.message} 
                onChange={handleChange} 
              ></textarea>
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
