import React from 'react';
import '../styles/CareerCard.css';

const CareerCard = ({ title, description, image }) => {
  return (
    <div className="career-card">
      <img src={image} alt={title} />
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
};

export default CareerCard;
