import React from 'react';
import img1 from '../assets/img1.jpg'; 
import { FaInfoCircle } from 'react-icons/fa';

import '../styles/HomePage.css';

const HomePage = () => {
  const features = [
    {
      title: "Employee Data",
      description: "Manage your employee information seamlessly with our robust Employee Master Data Management system. Centralize employee records for easy access and management.",
    },
    {
      title: "Payroll US",
      description: "Our payroll solution for the US is designed to handle all aspects of payroll processing with ease. Accurate payroll computations in compliance with federal and state laws.",
    },
    {
      title: "Payroll India",
      description: "Navigating the complexities of payroll in India can be challenging, but our dedicated payroll solution makes it simple. Automate payroll calculations, including taxes and deductions.",
    },
    {
      title: "Posting to Finance",
      description: "Ensure smooth financial operations with our automated posting to finance feature. Integrates payroll data directly into your financial software. Reduces manual data entry and minimizes errors.",
    },
    {
      title: "Bank Transfer",
      description: "Simplify your salary disbursements with our bank transfer capabilities. Secure and timely salary transfers to employees' bank accounts. Bulk payment processing to save time and effort.",
    },
    {
      title: "US Benefits",
      description: "In the United States, employee benefits are a crucial part of overall compensation packages and can significantly impact employee satisfaction and retention. Benefits like 401k, Health insurance etc.",
    }
  ];

  return (
    <div className="homepage">

      <section className="home-hero">
        <div className="hero-content">
          <h1>Simplifying Payroll</h1>
          <h1>Making it Simple</h1>
        </div>
      </section>

      <section className="features-section">
        <div className="features-header">
          <h2>Cloud Payroll</h2>
          <p>Accurate Pay Calculation with Best Customer experience and service</p>
        </div>
        <div className="features-grid">
          {features.map((feature, index) => (
            <div key={index} className="feature-item">
              <h3>
                <FaInfoCircle style={{ marginRight: '8px' }} />
                {feature.title}
              </h3>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="next-section">
        <div className="next-section-content">
          <h1>Cloud Payroll with </h1>
          <h1>Microservice Architecture</h1>
        </div>
      </section>

      <section className="content-section">
        <div className="content-text">
          <h2>Technology Stack</h2>
          <h3>Scalable, Accurate and Robust</h3>
          <p>Microservice Architecture using Java, Spring Boot, ReactJS</p>
          <p>
            We leverage the power of Microservice Architecture to build scalable, maintainable, and robust applications. 
            Utilizing Java and Spring Boot for backend services, we ensure efficient and secure data processing. Our frontend, 
            developed with ReactJS, provides a dynamic and responsive user experience. This modern architecture allows us 
            to deploy, manage, and scale individual services independently, ensuring optimal performance and flexibility 
            for your business needs.
          </p>
        </div>
        <div className="content-image">
          <img src={img1} alt="Technology Stack" />
        </div>
      </section>
    </div>
  );
};

export default HomePage;
