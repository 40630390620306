import React from 'react';
import '../styles/ProductsPage.css';

const Product = ({ title, description }) => {
  return (
    <div className="product">
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
};

const ProductsPage = () => {
  return (
    <div className="products-page">
      <h2>Our Products</h2>

      <Product
        title="Employee Master Data"
        description="Efficiently centralize and manage all employee information with our Employee Master Data solution. This feature provides a single, secure repository for storing comprehensive employee records, including personal details, job history, and contact information. With real-time updates and easy accessibility, you can ensure that your employee data is accurate and up-to-date, simplifying HR management and decision-making. Experience the ease of streamlined data handling and eliminate the challenges of fragmented employee information."
      />

      <Product
        title="Payroll India"
        description="Transform your payroll operations in India with our tailored Payroll India solution. This feature handles all aspects of employee compensation, including accurate wage calculations, tax deductions, and compliance with local regulations. Our system supports comprehensive reporting, covering both company-specific and localized requirements, ensuring that your payroll processes are precise, timely, and fully compliant. Enjoy the benefits of automated payroll management that reduces errors, saves time, and ensures that employees are paid correctly and on schedule."
      />

      <Product
        title="Payroll US"
        description="Streamline your payroll processing in the US with our specialized Payroll US solution. Designed to manage complex payroll requirements, this feature offers efficient calculations for wages, taxes, and deductions, along with comprehensive reporting capabilities. It covers both company-specific needs and localization requirements to ensure compliance with federal, state, and local regulations. Our system automates payroll tasks to minimize errors and provide accurate, timely payments, enhancing your payroll efficiency and employee satisfaction."
      />

      <Product
        title="Bank Transfer"
        description="Simplify and accelerate your payment processes with our Bank Transfer feature. This functionality enables seamless, automated transfers of funds directly into employees' bank accounts. By eliminating the need for manual processing and reducing the risk of errors, you ensure that your employees receive their compensation quickly and securely. Enjoy the convenience of reliable, efficient fund transfers and enhance your payroll operations with a solution that prioritizes accuracy and ease."
      />

      <Product
        title="Posting"
        description="Integrate your payroll transactions effortlessly with your organization’s accounting system using our Posting feature. This tool records payroll activities accurately and synchronizes them with your financial records, providing a clear and up-to-date view of your payroll-related transactions. Streamline your financial reporting and maintain consistent, accurate accounting records, reducing administrative overhead and ensuring financial integrity."
      />

      <Product
        title="US Benefits"
        description="Manage employee benefits with ease using our US Benefits module, which covers essential aspects such as 401(k) plans, insurance, and retirement benefits. This comprehensive solution allows you to efficiently administer a wide range of employee benefits, ensuring compliance with US regulations and providing valuable support to your workforce. Enhance your benefits administration and ensure your employees receive the perks they deserve, all while maintaining regulatory compliance and operational efficiency."
      />

      <Product
        title="Reporting"
        description="Elevate your business insights with our Reporting module. We provide a range of reports and analytics tailored to meet your specific needs, including localized, legal, and company-specific reports. Our system delivers accurate and timely insights, ensuring compliance with regulations and supporting strategic decision-making. From detailed payroll summaries to comprehensive compliance reports, our Reporting feature equips you with the data you need to drive informed decisions and maintain operational excellence."
      />

      <div className="final-section">
        <p>Discover how NumeroAlpha’s innovative products can transform your payroll management experience. Our cutting-edge solutions are designed to offer unparalleled accuracy, efficiency, and ease, helping you stay ahead in today’s dynamic business environment.</p>
      </div>
    </div>
  );
};

export default ProductsPage;
