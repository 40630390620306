import React from 'react';
import '../styles/TeamMemberCard.css';

const TeamMemberCard = ({ image, title, description }) => {
  return (
    <div className="team-member-card">
      <img src={image} alt={title} className="team-member-image" />
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
};

export default TeamMemberCard;
