import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ProductsPage from './pages/ProductsPage';
import CareersPage from './pages/CareersPage';
import ResourcesPage from './pages/ResourcesPage';
import AboutUsPage from './pages/AboutUsPage';
import ContactPage from './pages/ContactPage';

import Layout from './components/Layout';


function App() {
  return (
    <Router>
      <Layout>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/careers" element={<CareersPage/>} />
        <Route path="/about" element={<AboutUsPage />} />
        <Route path="/resources" element={<ResourcesPage/>} />
        <Route path="/contact" element={<ContactPage />} /> 

        {/* Add more routes here if needed */}
      </Routes>
      </Layout>
    </Router>
  );
}

export default App;
