import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/Navbar.css';
import logo from '../assets/logo.svg';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <NavLink to="/">
          <img src={logo} alt="NumeroAlpha Logo" />
        </NavLink>
      </div>
      <div className={`hamburger-icon ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <ul className={`navbar-links ${isMenuOpen ? 'show' : ''}`}>
        <li>
          <NavLink 
            exact 
            to="/" 
            activeClassName="active"
            onClick={toggleMenu}
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink 
            to="/products" 
            activeClassName="active"
            onClick={toggleMenu}
          >
            Products
          </NavLink> 
        </li>
        <li>    
          <NavLink 
            to="/careers" 
            activeClassName="active"
            onClick={toggleMenu}
          >
            Careers
          </NavLink> 
        </li>
        <li>
          <NavLink 
            to="/about" 
            activeClassName="active"
            onClick={toggleMenu}
          >
            About Us
          </NavLink>
        </li>
        <li>
          <NavLink 
            to="/resources" 
            activeClassName="active"
            onClick={toggleMenu}
          >
            Resources
          </NavLink> 
        </li>
        <li>
          <NavLink 
            to="/contact" 
            activeClassName="active"
            onClick={toggleMenu}
          >
            Contact Us
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
