import React from 'react';
import '../styles/ResourcesPage.css';
import image7 from '../assets/image7.png';
import image8 from '../assets/image8.png';
import image9 from '../assets/image9.png';
import image10 from '../assets/image10.png';
import image11 from '../assets/image11.jpg';

const articles = [
  {
    image: image7,
    title: "The Risks and Challenges of Microservices",
    description: "Explore the risks and challenges of microservices architecture, including issues such as complexity, data management, and performance bottlenecks.",
    link: "https://www.linkedin.com/pulse/dark-side-microservices-risks-challenges-modern-software-development-hi57c?utm_source=share&utm_medium=member_ios&utm_campaign=share_via"
  },
  {
    image: image8,
    title: "Synchronous vs Asynchronous Communication in Microservices",
    description: "Understanding communication in microservices architectures. Learn about the trade-offs, use cases, and best practices for synchronous and asynchronous communication.",
    link: "https://www.linkedin.com/pulse/exploring-synchronous-asynchronous-communication-microservices-ymomc?utm_source=share&utm_medium=member_ios&utm_campaign=share_via"
  },
  {
    image: image9,
    title: "Building a Payroll Product in 6 Months",
    description: "How to build a payroll product from concept to launch in 6 months. This guide covers the essential steps, challenges, and strategies for a successful launch.",
    link: "https://www.linkedin.com/pulse/building-payroll-product-from-concept-launch-6-months-wavexc-zlhrc?utm_source=share&utm_medium=member_ios&utm_campaign=share_via"
  },
  {
    image: image10,
    title: "Designing User-Friendly Payroll Products",
    description: "Balancing user needs and product features in payroll systems. Discover the key design principles and techniques to create an intuitive user experience.",
    link: "https://www.linkedin.com/pulse/crafting-exceptional-user-experience-payroll-products-balancing-sx2cc?utm_source=share&utm_medium=member_ios&utm_campaign=share_via"
  },
  {
    image: image11,
    title: "The U.S. Market for Payroll Systems",
    description: "Exploring the US market for payroll systems. Understand the opportunities, customer needs, and the competitive landscape to succeed in this market.",
    link: "https://www.linkedin.com/pulse/why-us-prime-market-selling-payroll-systems-numeroalphaa-zooic?utm_source=share&utm_medium=member_ios&utm_campaign=share_via"
  }
];


const ResourcesPage = () => {
  return (
    <div className="resources-page">
      <section className="resource-hero">
        <h1>Knowledge Hub</h1>
      </section>

      <section className="articles-section">
        <h2>Trending Articles</h2>
        {articles.map((article, index) => (
          <div key={index} className="article">
            <img src={article.image} alt={`Article ${index + 1}`} className="article-image" />
            <div className="article-content">
              <h3>{article.title}</h3>
              <p>{article.description}</p>
              <a href={article.link} target="_blank" rel="noopener noreferrer">
                <button>Details</button>
              </a>
            </div>
          </div>
        ))}
      </section>
    </div>
  );
};

export default ResourcesPage;
