import React from 'react';
import '../styles/CareersPage.css';
import CareerCard from '../components/CareerCard';
import image1 from '../assets/image1.png';
import image2 from '../assets/image2.png';
import image3 from '../assets/image3.png';
import image4 from '../assets/image4.png';
import image5 from '../assets/image5.png';
import image6 from '../assets/image6.png';

const CareersPage = () => {
  const careers = [
    {
      title: 'Intern - Java Spring Boot',
      description: `Join our dynamic team as a Java Spring Boot Intern, where you'll gain hands-on experience in developing and maintaining robust backend applications, learning best practices in Spring Boot, and contributing to real-world projects.`,
      image: image1,
    },
    {
      title: 'Intern - React UI',
      description: `We are looking for a passionate React UI Intern to collaborate with our development team, create engaging user interfaces, and enhance the user experience by building responsive and interactive web applications.`,
      image: image2,
    },
    {
      title: 'Intern - UX',
      description: `As a UX Intern, you'll work closely with our design team to research, design, and prototype user-centric interfaces, contributing to the creation of intuitive and visually appealing digital products. Figma tool experience is must.`,
      image: image3,
    },
    {
      title: 'Intern - Testing',
      description: `Join our QA team as a Testing Intern, where you'll learn and apply testing methodologies to ensure the quality and reliability of our software, gaining experience in both manual and automated testing processes.`,
      image: image4,
    },
    {
      title: 'Intern - Website Building',
      description: `We are seeking a Website Building Intern to assist in the design, development, and maintenance of our websites, learning web development technologies and improving site functionality and performance.`,
      image: image5,
    },
    {
      title: 'Intern - Reporting',
      description: `As a Reporting Intern, you'll support our data analytics team by creating and maintaining reports, analyzing data trends, and providing actionable insights to drive business decisions.`,
      image: image6,
    },
  ];

  return (
    <div className="careers-page">
      <div className="careers-header">
        <h1>We are Hiring</h1>
        <p>We are OK with no experience</p>
      </div>
      <div className="careers-grid">
  {careers.map((career, index) => (
    <a
      key={index}
      href="https://www.linkedin.com/company/numeroalphaa/jobs/"
      target="_blank"
      rel="noopener noreferrer"
      className="career-link"
    >
      <CareerCard
        title={career.title}
        description={career.description}
        image={career.image}
      />
    </a>
  ))}
</div>

    </div>
  );
};

export default CareersPage;
