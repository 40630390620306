import React from 'react';
import '../styles/AboutUsPage.css';
import image12 from '../assets/image12.png';
import image13 from '../assets/image13.png';
import image14 from '../assets/image14.png';
import image15 from '../assets/image15.png';
import image16 from '../assets/image16.png';
import image17 from '../assets/image17.png';
import TeamMemberCard from '../components/TeamMemberCard';

const AboutUsPage = () => {
  return (
    <div className="about-us-page">
      <section className="about-hero">
        <h1>Who Are we ?</h1>
      </section>
      <section className="description-section">
        <p>
          We are a passionate team dedicated to making payroll simple. Our goal is to accurate payroll with excellence in customer support and high customer experience.
        </p>
        <p>
          Since our founding in 2024, we have been committed to building Employee Master Data, Payroll US, US Benefits, Payroll India. Our team of experts brings together diverse skills and backgrounds, united by a common purpose: to innovate, inspire, and excel.
        </p>
      </section>
      <section className="team-section">
        <TeamMemberCard
          image={image12}
          title="Architect"
          description="Our Architects design the blueprints for success. They create robust, scalable, and efficient system architectures that form the foundation of our products, enabling us to build reliable solutions."
        />
        <TeamMemberCard
          image={image13}
          title="Product Manager"
          description="They bridge the gap between market needs and our technical capabilities, ensuring that our products not only meet customer demands but also set new industry standards."
        />
        <TeamMemberCard
          image={image14}
          title="Full Stack Developer"
          description="Our Full Stack Backend Developers are the backbone of our development team. They seamlessly integrate front-end and back-end technologies to create cohesive, end-to-end solutions."
        />
        <TeamMemberCard
          image={image15}
          title="UI Developer"
          description="Our UI Developers craft intuitive and engaging interfaces. They focus on creating visually appealing and user-friendly designs that enhance the overall user experience and make our products stand out."
        />
        <TeamMemberCard
          image={image16}
          title="Java Expert"
          description="Our Java Experts bring deep knowledge and experience to our team. They specialize in developing powerful, scalable, and secure Java applications, ensuring that our solutions are robust and reliable."
        />
        <TeamMemberCard
          image={image17}
          title="UX Expert"
          description="Our UX Experts are dedicated to understanding user needs and behaviors. They design seamless and enjoyable user experiences, making sure that our products are not only functional but also delightful to use."
        />
      </section>
    </div>
  );
};

export default AboutUsPage;
